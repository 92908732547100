<template>
  <base-section id="about-our-product">
    <base-section-heading
      color="grey lighten-2"
      icon="mdi-vuetify"
      title="Connexion"
    />
    <v-form>
      <v-container>
        <v-layout>
          <v-flex>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="password"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="passwordVisible ? 'text' : 'password'"
                  :rules="passwordRules"
                  name="input-10-1"
                  label="Mot de passe"
                  counter
                  @click:append="passwordVisible = !passwordVisible"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-btn @click="submit">S'identifier</v-btn>
              </v-col>
            </v-row>
          </v-flex>
        </v-layout>
        <v-snackbar v-model="showAlert" :top="true">
          {{ alert }}
          <v-btn color="pink" text @click="resetForm">
            Fermer
          </v-btn>
        </v-snackbar>
      </v-container>
    </v-form>
  </base-section>
</template>
<script>
export default {
  name: "Login",

  data: () => ({
    email: "",
    password: "",
    passwordVisible: false,
    emailRules: [
      v => !!v || "Vous devez saisir une adresse  email",
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Saisissez une adresse email valide"
    ],
    passwordRules: [v => !!v || "Vous devez saisir un mot de passe"],
    showAlert: false,
    alert: ""
  }),
  methods: {
    submit: function() {
      const vm = this;

      vm.email = vm.email.toLowerCase().trim();

      this.$store

        .dispatch("logInUser", {
          email: vm.email,
          password: vm.password
        })

        .then(() => {
          if (this.$route.params.nextUrl != null) {
            this.$router.push(this.$route.params.nextUrl);
          } else {
            if (this.$store.getters.isAdmin == 1) {
              this.$router.push("admin");
            } else {
              this.$router.push("home");
            }
          }
        })

        .catch(() => {
          console.log("failed");
          this.alert = "Echec de l'authentification";
          this.showAlert = true;
        });
    },

    resetForm: function() {
      this.showAlert = false;
      this.password = "";
    }
  }
};
</script>
